.gridTableBox {
  max-height: 85vh;
  height: 70vh;
}
@media screen and (max-width: 600px) {
  .table {
    padding-bottom: 10px !important;
  }
  .gridTableBox {
    max-height: 57vh;
  }
  .tableCard {
    margin-top: 0px !important;
  }
}

.gridTableBox {
  overflow: auto;
  height: auto;

  tbody {
    tr.gridRow:nth-of-type(odd) {
      td {
        background-color: #fafafa;
      }
    }
  }
  .gridTableFreeze thead tr:first-child td {
    box-shadow: none;
  }
  .gridTableFreeze {
    margin: 0 15px;
  }

  .gridTableFreeze .GridViewScrollItem TD {
    font-family: "Helvetica";
    font-size: 14px;
    font-weight: normal;
    padding: 5px 10px !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: normal;
    color: #656565;
  }
  .GridViewScrollItem {
    height: 51px;
    margin: 11px 0 0;
    padding: 17px;
  }

  .alignFlex {
    display: flex;
    align-items: center;
  }
}

.gridTableFreeze .GridViewScrollHeader TD {
  padding: 0px 5px !important;
  font-family: "Helvetica" !important;
  font-size: 14px;
  // font-weight: bold !important;
  font-stretch: normal;
  padding: 5px 10px !important;
  font-style: normal;
  line-height: 19px !important;
  letter-spacing: normal !important;
  color: #000000 !important;
  .padding-left {
    padding: 0px 10px !important;
  }
}

.gridTableFreeze {
  .switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 10px;
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #d9d9d9;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      &:before {
        position: absolute;
        content: "";
        height: 16px;
        top: -3.4px;
        width: 16px;
        left: 0px;
        bottom: -2px;
        background-color: #989898;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
      &.round {
        border-radius: 34px;
      }
      &.round:before {
        border-radius: 50%;
      }
    }

    input:checked + .slider {
      background-color: #f2cccc;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #f2cccc;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(20px);
      -ms-transform: translateX(20px);
      transform: translateX(20px);
      background-color: #dc3545;
    }
  }

  &.scrolledFromLeft {
    tr {
      td:first-child,
      th:first-child {
        box-shadow: 1px 0 7px -5px #888;
      }
    }
  }
  .actionColumnMain {
    .action-icon {
      color: #d52417;
      font-family: "Helvetica";
      margin-right: 5px;
      margin-left: 5px;
      cursor: pointer;
    }
    .disabled-action {
      color: #6c757d;
      cursor: not-allowed;
    }
    .actionColumn .actionSeprator {
      border-left: 1px solid #111111;
    }
    .actionColumn:first-child .actionSeprator {
      border-left: none;
    }
  }

  a.sort-by {
    position: relative;
    display: inline;
    text-decoration: none;
    &.block {
      display: block;
    }
  }
  a.sort-by:before,
  a.sort-by:after {
    border: 4px solid transparent;
    content: "";
    display: block;
    height: 0;
    right: -11px;
    top: 55%;
    position: absolute;
    width: 0;
  }
  // a.sort-by:before {
  //   margin-top: -9px;
  //   border-bottom-color: #111111;
  // }
  a.sort-by.ASC:after {
    border-top-color: #919191;
  }
  a.sort-by.DESC:before {
    border-bottom-color: #919191;
  }
  // a.sort-by:after {
  //   margin-top: 1px;
  //   border-top-color: #111111;
  // }
  .sorting-table a.sort-by {
    color: inherit;
    &.min-width {
      width: min-content;
    }
  }

  .card {
    border: 1px solid #80808047;
  }
  td:first-child,
  th:first-child {
    position: sticky;
    left: 0;
    z-index: 12;

    background-color: #ffffff;
  }
  thead tr th {
    position: sticky;
    top: 0;
    background: #ffff;
    color: #111111;
  }
  thead tr th:first-child {
    z-index: 13;
  }
  thead tr:first-child td,
  thead tr:first-child th {
    position: sticky;
    padding-right: 12px;
    top: -1px;
    border: none;
    background-color: white;
    z-index: 20;
    box-shadow: 0px 1px #e6e6e6;
  }
  thead tr:first-child td:first-child {
    z-index: 30;
  }

  .GridViewScrollHeader TH,
  .GridViewScrollHeader TD {
    padding: 10px 5px;
    font-weight: normal;
    white-space: break-spaces !important;
    border-bottom: 1px solid #eaeaea;
    background-color: #ffffff;
    text-align: left;
    vertical-align: middle;
  }
  .GridViewScrollHeader TD {
    font-family: "Helvetica";
    font-size: 14px;
    letter-spacing: 0.72px;
    color: #24313e !important;
    line-height: normal;
  }

  .GridViewScrollHeader td {
    height: 45px;
    &.text-center {
      text-align: center;
    }
  }
  .GridViewScrollItemFreeze TD,
  .GridViewScrollItem TD {
    padding: 5px 5px;
    height: 45px;
    font-family: "Helvetica";
    font-size: 14px;
    letter-spacing: 0.72px;
    line-height: normal;
    color: #111111;
    white-space: break-spaces;
    border-bottom: 1px solid #e6e6e6;
    background-color: #ffffff;
    vertical-align: middle;
    //box-shadow: 1px 0 7px -5px #888;
  }
  .GridViewScrollItemFreeze {
    td,
    th {
      box-shadow: 0px 8px 7px 4px #888;
      border-right: 1px solid #eaeaeadb;
    }
  }
  .GridViewScrollItemFreeze {
    div {
      display: flex;
      align-items: center;
    }
  }

  .mediumGrid,
  .cm-12 {
    min-width: 156px !important;
    max-width: 156px !important;
    width: 156px !important;
  }
  .cm-20 {
    min-width: 306px !important;
    max-width: 306px !important;
    width: 306px !important;
  }
  .verySmallGrid,
  .cm-8 {
    min-width: 60px !important;
    max-width: 60px !important;
    width: 60px !important;
  }
  .cm-9 {
    min-width: 85px !important;
    max-width: 85px !important;
    width: 85px !important;
  }
  .smallGrid,
  .cm-10 {
    min-width: 126px !important;
    max-width: 126px !important;
    width: 126px !important;
  }
  .smallGrid-1 {
    min-width: 135px !important;
    max-width: 135px !important;
    width: 135px !important;
  }
  .cm-11 {
    min-width: 140px !important;
    max-width: 140px !important;
    width: 140px !important;
  }
  .cm-13 {
    min-width: 165px !important;
    max-width: 165px !important;
    width: 165px !important;
  }
  .largeGrid,
  .cm-15 {
    min-width: 190px !important;
    max-width: 190px !important;
    width: 190px !important;
  }
  .text-center {
    text-align: center;
  }
  .padding-left {
    padding-left: 8px;
  }
}

.selectBox,
.inputBox {
  padding: 5px;
  height: 35px;
  max-width: 40px;
  width: fit-content;
  min-width: 75px;
  text-align: center;
}

.form-group {
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}
.datepicker > .form-control {
  height: calc(2rem + 2px);
}
select.form-control:not([size]):not([multiple]) {
  height: calc(1.8em + 0.75rem + 2px);
}
select.searchselect:not([size]):not([multiple]) {
  height: calc(2rem + 2px);
  margin-left: 15px;
}
.searchselect {
  flex: 0.3 0 auto !important;
  padding: 0.275rem 0.75rem;
  font-size: 0.9rem;
}
.Searchselect {
  height: calc(1.4em + 0.75rem + 2px) !important;
}
.form-control {
  height: calc(1.8em + 0.75rem + 2px);
  font-size: 0.9rem;
  border: 1px solid #e2e2e2;
  &.m-search {
    height: 38px;
  }
}
.searchinput {
  flex: 1 0 auto;
  height: calc(1.4em + 0.75rem + 2px);
}
#scrollBox {
  border: none;
  border-radius: 4px;
  overflow: auto;
}
#scrollBox .activate-box {
  background-image: linear-gradient(90deg, var(--color) 50%, transparent 50%),
    linear-gradient(90deg, var(--color) 50%, transparent 50%),
    linear-gradient(0deg, var(--color) 50%, transparent 50%),
    linear-gradient(0deg, var(--color) 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 2px, 15px 2px, 2px 15px, 2px 15px;
  background-position: left top, right bottom, left bottom, right top;
  //border: 1px dashed #0f2c58 !important;
  animation: border-dance 1s infinite linear;
  border: none;
}

@keyframes border-dance {
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }
  100% {
    background-position: left 15px top, right 15px bottom, left bottom 15px,
      right top 15px;
  }
}

.tableCard {
  box-shadow: none !important;
  margin-top: 10px;
  margin-bottom: 10px;
  //border: 1px solid #e2e2e2 !important;
}
.table td {
  border-top: none;
}
.gridTableBox {
  margin-bottom: 5px !important;
}
/* width */
.gridTableBox::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

/* Track */
.gridTableBox::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #979797;
  border-radius: 2px !important;
  margin-left: 15px;
}

/* Handle */
.gridTableBox::-webkit-scrollbar-thumb {
  background: #0178c8 !important;
  border-radius: 1px !important;
}
.gridTableBox tbody tr td label {
  vertical-align: bottom !important;
  margin: 10px 10px 9px 0 !important;
}
.checkboxContainer {
  label {
    font-size: 14px !important;
    // font-weight: 900 !important;
    color: #003a9e;
    font-family: "Helvetica";
    letter-spacing: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &::before {
      margin-top: 0px !important;
    }
  }
}
.gridTableBox .gridTableFreeze {
  margin: 0 !important;
}

/* Handle on hover */
.percentageBarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .percentageBar {
    height: 6px;
    width: 80%;
    border-radius: 3px;
    background: #e5e5e5;
    div {
      border-radius: 3px;
      height: 6px;
      background: #d52417;
    }
  }
}

.plusNumbers {
  a {
    color: #d52417;
    font-family: "Helvetica";
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
  }
}

.tablePagination {
  .row {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 600px) {
      flex-direction: column;
      margin-bottom: 40px;
      justify-content: center;
      align-items: center;
    }
  }
  .pagination-text {
    font-size: 15.2px;
    font-family: "Lato";
    color: #3f495b;
    font-weight: bold;
    letter-spacing: normal;
    @media screen and (max-width: 600px) {
      margin: 10px;
    }
    .lightText {
      color: rgb(170, 178, 192);
      font-weight: normal;
    }
  }
  .pageText {
    color: #aab2c0;
    font-size: 15.2px;
    font-family: "Lato";
  }
  .pagination {
    display: flex;
    list-style: none;
    margin: 0px;
    padding-left: 15px;
    li {
      button {
        color: #c7cdd9;
        min-width: 28px;
        height: 28px;
        font-size: 15.2px;
        font-family: "Lato";
        background: transparent;
        border: 1px solid #c7cdd9;
        cursor: pointer;
        border-radius: 2.8px;
        margin-left: 10px;
      }
      &.active {
        button {
          background: #0178c8;
          color: #ffffff;
          border: 1px solid #0178c8;
        }
      }
    }
  }
}
.form-check {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .form-check-input {
    margin-top: 12px;
    margin-right: 12px;
    cursor: pointer;
  }
}

.sort-icon {
  margin-left: 6px;
}
